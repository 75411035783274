import { graphql } from "gatsby";
import React from "react";

export default function Search() {
  return <></>;
}

// export const query = graphql`
//   query {
//     queryLocation: allSanityLocation(filter: { language: { eq: "de" } }) {
//       nodes {
//         place
//         title
//         id
//       }
//     }
//     queryKultur: allSanityKultur {
//       nodes {
//         typeOfEvent
//         place
//         id
//         eventDate
// linkEvent;
//         title
//       }
//     }
//     queryKulinarik: allSanityKulinarik(filter: { language: { eq: "de" } }) {
//       nodes {
//         id
//         place
//         title
//       }
//     }

//     queryMieten: allSanityMieten(filter: { language: { eq: "de" } }) {
//       nodes {
//         id
//         place
//         title
//       }
//     }
//   }
// `;
